export const spanishCountries = [
    "ARG", // Argentina
    "BOL", // Bolivia
    "CHL", // Chile
    "COL", // Colombia
    "CRI", // Costa Rica
    "CUB", // Cuba
    "DOM", // República Dominicana
    "ECU", // Ecuador
    "ESP", // España
    "GTM", // Guatemala
    "HND", // Honduras
    "MEX", // México
    "NIC", // Nicaragua
    "PAN", // Panamá
    "PRY", // Paraguay
    "PER", // Perú
    "PRI", // Puerto Rico
    "SLV", // El Salvador
    "URY", // Uruguay
    "VEN", // Venezuela
    "GQ",  // Guinea Ecuatorial
    "PHL"  // Filipinas
];
