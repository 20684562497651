import React, { useState, useEffect } from 'react';
import { useRegion } from '../contexts/RegionContext';
import {
  contactTitle as contactTitleEn,
  contactSubtitle as contactSubtitleEn,
  contactFields as contactFieldsEn,
  contactButton as contactButtonEn
} from '../constants/en/ContactForm.js';
import {
  contactTitle as contactTitleEs,
  contactSubtitle as contactSubtitleEs,
  contactFields as contactFieldsEs,
  contactButton as contactButtonEs
} from '../constants/es/ContactForm';
import { spanishCountries } from '../constants/data';
import Footer from './Footer.jsx';
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';

const ContactForm = () => {
  const { region } = useRegion();
  const [language, setLanguage] = useState({
    title: contactTitleEn,
    subtitle: contactSubtitleEn,
    fields: contactFieldsEn,
    button: contactButtonEn
  });

  useEffect(() => {
    if (spanishCountries.includes(region)) {
      setLanguage({
        title: contactTitleEs,
        subtitle: contactSubtitleEs,
        fields: contactFieldsEs,
        button: contactButtonEs
      });
    } else {
      setLanguage({
        title: contactTitleEn,
        subtitle: contactSubtitleEn,
        fields: contactFieldsEn,
        button: contactButtonEn
      });
    }
  }, [region]);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_m2tx5hl', 'template_k55nc7o', e.target, '1-fEDjC8I-ihLeoje')
      .then((result) => {
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Message sent successfully!'
        });
      }, (error) => {
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: 'An error occurred, please try again.'
        });
      });
  
    e.target.reset();
  };
  return (
    <section className="section contact-section" id="contactSection">
      <div className="contact-container">
        <aside className="left-container">
          <h1 className="contact-title">We can</h1>
          <h1 className="contact-title">make it</h1>
          <h1 className="contact-title">real.</h1>
          <p>Contact us for more information.</p>
        </aside>
        <aside className='right-container'>
          <h2 className="contact-title">{language.title}</h2>
          <p className="contact-subtitle">{language.subtitle}</p>
          <form onSubmit={sendEmail}>
            <div className="form-group">
              <input
                type="text"
                className="form-input"
                name="user_name"
                placeholder={language.fields.namePlaceholder}
                required
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                className="form-input"
                name="user_email"
                placeholder={language.fields.emailPlaceholder}
                required
              />
            </div>
            <div className="form-group">
              <textarea
                className="form-textarea"
                rows="4"
                name="message"
                placeholder={language.fields.messagePlaceholder}
                required
              ></textarea>
            </div>
            <button
              type="submit"
              className="form-button"
            >
              {language.button}
            </button>
          </form>
        </aside>
      </div>
      <Footer />
    </section>
  );
};

export default ContactForm;
