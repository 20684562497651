import * as React from "react"
const LinkedinIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 300 300"
    fill="#fff"
    {...props}
  >
    <path d="M49 1.6C25.8 6.5 6.4 26.2 1.5 49.5c-2.2 10.4-2.2 190.6 0 201 4.9 23.6 24.4 43.1 48 48 10.4 2.2 190.6 2.2 201 0 23.6-4.9 43.1-24.4 48-48 2.2-10.4 2.2-190.6 0-201-4.9-23.6-24.4-43.1-48-48C240.4-.6 58.9-.6 49 1.6zm43.5 41.9c9.3 5.4 12.9 17.5 8.2 27.8-6.2 13.5-23.4 17-34 6.9-12-11.4-8-31 7.5-36.9 4.9-1.9 13.1-.9 18.3 2.2zm7.3 125.2-.3 68.8h-37l-.3-67.5c-.1-37.1 0-68.1.3-68.8.3-.9 5.1-1.2 19-1.2H100l-.2 68.7zm62.7-57.9c0 5.6.2 10.2.5 10.2.3-.1 1.7-1.8 3.2-4 6.1-8.9 20.2-15.7 34.3-16.7 18.9-1.3 35.8 8.1 43.5 24.2 5.6 11.8 6.1 17 5.8 67.5l-.3 45.5h-37l-.5-41c-.5-40.7-.5-41-2.9-45.8-7.9-15.9-27.8-18.2-39.5-4.6-6.5 7.6-6.7 9.2-6.9 52.6l-.2 38.8h-37l-.3-68.8-.2-68.7 18.7.2 18.8.3v10.3z" />
  </svg>
)
export default LinkedinIcon
