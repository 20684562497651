export const contactTitle = "Contáctenos";
export const contactSubtitle = "¡Nos encantaría saber de usted!";
export const contactFields = {
  name: "Nombre",
  namePlaceholder: "Su nombre",
  email: "Correo electrónico",
  emailPlaceholder: "Su correo electrónico",
  message: "Mensaje",
  messagePlaceholder: "Su mensaje"
};
export const contactButton = "Enviar";
