import { useEffect, useState } from "react";
import { useRegion } from "../contexts/RegionContext";
import { title as titleEn, paragraph as pEn, aside as asideEn } from "../constants/en/Card";
import { title as titleEs, paragraph as pEs, aside as asideEs } from "../constants/es/Card";
import { spanishCountries } from "../constants/data";

export default function Cards() {
    const [language, setLanguage] = useState({
        title: titleEn,
        paragraph: pEn,
        aside: asideEn
    });
    const { region } = useRegion();

    useEffect(() => {
        if (spanishCountries.includes(region)) {
            setLanguage({
                title: titleEs,
                paragraph: pEs,
                aside: asideEs
            });
        } else {
            setLanguage({
                title: titleEn,
                paragraph: pEn,
                aside: asideEn
            });
        }
    }, [region]);
    return (
        <div className="cards">
            <aside className="card-1">
            <h1>
                {language.title}
            </h1>
            <p>
                {language.paragraph}
            </p>
            </aside>
            <aside className="card-2">
                {language.aside}
            </aside>
        </div>

    )
}