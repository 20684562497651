// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../public/fonts/AlbertSans-Medium.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'albert sans';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
}

html{
  scroll-behavior: smooth;
  overflow-x: hidden;
  font-family: 'albert sans', sans-serif;
}
body{
  min-height: 100vh;
  margin: 0;
}
#root{
  min-height: 100vh;
}
.section{
  max-height: 100vh;
  scroll-snap-align: end;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

main {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Esto hace que el contenido ocupe al menos toda la altura de la ventana */
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,+DAAoE;AACtE;;AAEA;EACE,uBAAuB;EACvB,kBAAkB;EAClB,sCAAsC;AACxC;AACA;EACE,iBAAiB;EACjB,SAAS;AACX;AACA;EACE,iBAAiB;AACnB;AACA;EACE,iBAAiB;EACjB,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;EACtB,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAiB,EAAE,2EAA2E;AAChG","sourcesContent":["@font-face {\n  font-family: 'albert sans';\n  src: url('../public/fonts/AlbertSans-Medium.ttf') format('truetype');\n}\n\nhtml{\n  scroll-behavior: smooth;\n  overflow-x: hidden;\n  font-family: 'albert sans', sans-serif;\n}\nbody{\n  min-height: 100vh;\n  margin: 0;\n}\n#root{\n  min-height: 100vh;\n}\n.section{\n  max-height: 100vh;\n  scroll-snap-align: end;\n}\n\n* {\n  box-sizing: border-box;\n  margin: 0;\n  padding: 0;\n}\n\nmain {\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh; /* Esto hace que el contenido ocupe al menos toda la altura de la ventana */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
