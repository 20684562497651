import { useEffect, useState } from "react";
import { useRegion } from "../contexts/RegionContext";
import { firsTitle as firsTitleEn, secondTitle as secondTitleEn } from "../constants/en/Init";
import { firsTitle as firsTitleEs, secondTitle as secondTitleEs } from "../constants/es/Init";

export function Title() {
    const { region } = useRegion();
    const [language, setLanguage] = useState({
        firsTitle: firsTitleEn,
        secondTitle: secondTitleEn,
    });

    useEffect(() => {
        if (region === "ARG") {
            setLanguage({
                firsTitle: firsTitleEs,
                secondTitle: secondTitleEs,
            });
        } else {
            setLanguage({
                firsTitle: firsTitleEn,
                secondTitle: secondTitleEn,
            });
        }
    }, [region]);

    return (
        <div className="sectionTitle">
            <section id="firstSectionTitle">
                <h1 className="title blue">
                    {language.firsTitle}
                </h1>
            </section>
            <section id="secondSectionTitle">
                <h1>
                    {language.secondTitle}
                </h1>
            </section>
        </div>
    )
}