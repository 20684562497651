import * as React from "react"
const FlagSpanish = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    viewBox="0 0 512 512"
    id="es"
    style={{ fill: "red ", backgroundColor: "yellow", borderRadius: "50%" }}
    {...props}
  >
    <path d="M240 .6c-42 3.9-70.7 12-103.8 29.4C97 50.6 60.8 84.7 37.4 123c-5.5 9.1-16.3 30.8-19.8 39.7L16 167h480l-1.6-4.3c-3.7-9.9-17.8-37-24.3-46.7-20.3-30.5-48.1-57.9-77.7-76.5C357.7 17.7 318.2 4.6 276.5 1 265.3.1 248-.1 240 .6zM17.6 349.2c.9 2.4 4.5 10.1 7.9 17.3C59.1 437.2 125 489.4 201.6 506c46.6 10.1 97.9 6.7 142-9.5 14.6-5.3 40.3-18.3 53.2-26.9 30.6-20.3 58.9-49.7 77.8-80.6 5.5-9.1 16.3-30.8 19.8-39.8l1.6-4.2H16l1.6 4.2z" />
  </svg>
)
export default FlagSpanish
