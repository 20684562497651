import { createContext, useContext, useEffect, useState } from 'react';

const RegionContext = createContext();

export const useRegion = () => {
    return useContext(RegionContext);
};

export const RegionProvider = ({ children }) => {
    const [region, setRegion] = useState(null);

    useEffect(() => {
        fetch('https://ipapi.co/json/')
        .then(response => response.json())
        .then(data => {
            const userRegion = data.country_code_iso3;
            setRegion(userRegion);
        })
        .catch(error => {
            console.error('Error fetching user region:', error);
        });
    }, []);

    return (
        <RegionContext.Provider value={{ region, setRegion }}>
        {children}
        </RegionContext.Provider>
    );
};
