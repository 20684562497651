import LogoSVG from "./Logo";
import { navbar as navbarEn } from "../constants/en/Init";
import { navbar as navbarEs } from "../constants/es/Init";
import { useRegion } from "../contexts/RegionContext";
import { useEffect, useState } from "react";
import { spanishCountries } from "../constants/data";
import FlagEEUU from "./FlagEEUU";
import FlagSpanish from "./FlagSpanish";

export default function Navbar() {
    const { region, setRegion } = useRegion();
    const [language, setLanguage] = useState({
        navbar: navbarEn
    });
    const [showMenu, setShowMenu] = useState(false);
    const [flag, setFlag] = useState("en");

    useEffect(() => {
        if (spanishCountries.includes(region)) {
            setLanguage({
                navbar: navbarEs
            });
            setFlag("es");
            setRegion("ARG");
        } else {
            setLanguage({
                navbar: navbarEn
            });
            setFlag("en");
            setRegion("USA");
        }
    }, [region, setRegion]);

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    }

    const handleFlag = (newOpt) => {
        if (newOpt === "es") {
            setLanguage({
                navbar: navbarEs
            });
            setRegion("ARG");
        } else {
            setLanguage({
                navbar: navbarEn
            });
            setRegion("USA");
        }
        setFlag(newOpt);
        setShowMenu(false);
    }

    return (
        <nav className="navbar flex">
            <LogoSVG width="250" />
            <div className="flex navbar-links">
                {language.navbar.map((item, index) => (
                    <a key={index} className="blue" href={item.href}>
                        {item.label}
                    </a>
                ))}
                <div className="dropdown">
                    <button className="dropbtn" onClick={toggleMenu}>
                        {flag === "es" ? <FlagSpanish /> : <FlagEEUU />}
                    </button>
                    <div className={`dropdown-content ${showMenu ? "show" : ""}`}>
                        <button onClick={() => handleFlag("es")}><FlagSpanish /></button>
                        <button onClick={() => handleFlag("en")}><FlagEEUU /></button>
                    </div>
                </div>
            </div>
        </nav>
    );
}
