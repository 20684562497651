import React from 'react';
import Logo2 from './Logo2';
import InstragramIcon from './InstagramIcon';
import LinkedinIcon from './LinkedinIcon';

export default function Footer() {

    return (
        <div className="footer">
            <div className="footer-container">
                <div className="footer-logo">
                    <Logo2 />
                </div>
                <div className='footer-links'>
                    <div>
                        <ul className='footer-ul'>
                            <li className='footer-li'>
                                <LinkedinIcon />
                                <a target='_blank' rel='noreferrer' href="https://www.linkedin.com/company/chimpsdev/">Linkedin</a>
                            </li>
                            <li className='footer-li'>
                                <InstragramIcon />
                                <a target='_blank' rel='noreferrer' href="https://www.instagram.com/chimpsdev/">Instagram</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div>
                    <h3>© ChimpsDev 2024. All Rights Reserved.</h3>
                </div>
            </div>
        </div>
    )
}