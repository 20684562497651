export const contactTitle = "Contact Us";
export const contactSubtitle = "We'd love to hear from you!";
export const contactFields = {
  name: "Name",
  namePlaceholder: "Your name",
  email: "Email",
  emailPlaceholder: "Your email",
  message: "Message",
  messagePlaceholder: "Your message"
};
export const contactButton = "Send";