import Logo2 from "./Logo2";
import phone from "../images/phone.png"
import carrito from "../images/carrito.PNG";
import { useRegion } from "../contexts/RegionContext";
import { useEffect, useState } from "react";
import { 
    leftTitle as leftTitleEn, 
    rightTitle as rightTitleEn, 
    leftSubtitle as leftSubtitleEn, 
    rightSubtitle as rightSubtitleEn,
    footerLeftTitle as footerLeftTitleEn, 
    footerRight as footerRightEn 
} from "../constants/en/PhoneCard";
import { 
    leftTitle as leftTitleEs, 
    rightTitle as rightTitleEs, 
    leftSubtitle as leftSubtitleEs, 
    rightSubtitle as rightSubtitleEs,
    footerLeftTitle as footerLeftTitleEs, 
    footerRight as footerRightEs 
} from "../constants/es/PhoneCard";
import { spanishCountries } from "../constants/data";

export default function ThirdSection() {

    const { region } = useRegion();
    const [language, setLanguage] = useState({
        leftTitle: leftTitleEn,
        rightTitle: rightTitleEn,
        leftSubtitle: leftSubtitleEn,
        rightSubtitle: rightSubtitleEn,
        footerLeftTitle: footerLeftTitleEn,
        footerRight: footerRightEn
    });

    useEffect(() => {
        if (spanishCountries.includes(region)) {
            setLanguage({
                leftTitle: leftTitleEs,
                rightTitle: rightTitleEs,
                leftSubtitle: leftSubtitleEs,
                rightSubtitle: rightSubtitleEs,
                footerLeftTitle: footerLeftTitleEs,
                footerRight: footerRightEs
            });
        } else {
            setLanguage({
                leftTitle: leftTitleEn,
                rightTitle: rightTitleEn,
                leftSubtitle: leftSubtitleEn,
                rightSubtitle: rightSubtitleEn,
                footerLeftTitle: footerLeftTitleEn,
                footerRight: footerRightEn
            });
        }

    }, [region]);


    return (
        <>
            <div id="firstDivThirdSection">
                <h1>{language.leftTitle}</h1>
                <Logo2 />
                <p>{language.rightTitle}</p>
            </div>
            <div id="secondDivThirdSection">
                <aside>
                    <h1>
                        {language.leftSubtitle}
                    </h1>
                    <p>
                        iOS / Android
                    </p>
                </aside>
                <aside>
                    <img src={phone} alt="Tremendo fono" id="phoneImg"/>
                </aside>
            </div>
            <div>
                <aside></aside>
                <aside id="thirdDivThirdSection" className="flex">
                    <img src={carrito} alt="" />
                    <div>
                        <h1>ChimpCommerce</h1>
                        <p>{language.rightSubtitle}</p>
                    </div>
                </aside>
            </div>
            <div id="fourthDivThirdSection">
                <aside className="asideFourth">
                    <h1>
                        {language.footerLeftTitle}
                    </h1>
                    <p className="pFourth">SEO Ready, SSL, Streaming and Soport</p>
                </aside>
                <aside className="asideFifth">
                    <h1>ChimpAI</h1>
                    {
                        language.footerRight.map((item, index) => (
                            <p key={index} className="pFourth">{item}</p>
                        ))
                    }
                </aside>
            </div>
        </>

    )

}