import './App.css';
import Navbar from './fragments/Navbar';
import { Title } from './fragments/Title';
import Cards from './fragments/Cards';
import ThirdSection from './fragments/ThirdSection';
import { RegionProvider } from './contexts/RegionContext';
import ContactForm from './fragments/ContactForm';
import BackToTopButton from './fragments/BackToTopButton';

function App() {
  return (
    <RegionProvider>
      <main>
          <Navbar />
          <section className='section' id="firstSection">
            <Title />
          </section>
          <section className='section' id="secondSection">
            <Cards />
          </section>
          <section className='section' id="thirdSection">
            <ThirdSection />
          </section>
          <section className='section' id="contactSection">
            <ContactForm />
          </section>
          <BackToTopButton />
      </main>
    </RegionProvider>
  );
}

export default App;
